import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import WithLayoutRoute from './WithLayoutRoute';
import DashboardLayout from 'layouts/Dashboard/Dashboard';
import LinearProgress from '@material-ui/core/LinearProgress';
import LoadForm from 'pages/LoadForm/LoadForm';

const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const Tables = React.lazy(() => import('pages/Tables/Tables'));
const Typography = React.lazy(() => import('pages/Typography/Typography'));
const Icons = React.lazy(() => import('pages/Icons/Icons'));
const Categories = React.lazy(() => import('pages/Categories/Categories'));
const Companies = React.lazy(() => import('pages/Companies/Companies'));
const Products = React.lazy(() => import('pages/Products/Products'));
const Purchases = React.lazy(() => import('pages/Purchases/Purchases'));
const Shops = React.lazy(() => import('pages/Shops/Shops'));
const Orders = React.lazy(() => import('pages/Orders/Orders'));
const Login = React.lazy(() => import('pages/Login/Login'));
const Expenses = React.lazy(() => import('pages/Expenses/Expenses'));
const PushNotifications = React.lazy(() => import('pages/PushNotifications/PushNotifications'));
const ProductNotificationsSchedule = React.lazy(() =>
  import('pages/ProductNotificationsSchedule/ProductNotificationsSchedule')
);
const Riders = React.lazy(() => import('pages/Riders/Riders'));

function Routes() {
  return (
    <React.Suspense
      fallback={
        <LinearProgress
          style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '10000' }}
        />
      }
    >
      <Switch>
        <Route exact path="/login" component={Login} />

        <WithLayoutRoute component={Dashboard} exact layout={DashboardLayout} path="/dashboard" />
        <WithLayoutRoute component={Tables} exact layout={DashboardLayout} path="/tables" />
        <WithLayoutRoute component={Typography} exact layout={DashboardLayout} path="/typography" />
        <WithLayoutRoute component={Icons} exact layout={DashboardLayout} path="/icons" />

        <WithLayoutRoute component={Categories} exact layout={DashboardLayout} path="/categories" />
        <WithLayoutRoute component={Companies} exact layout={DashboardLayout} path="/companies" />
        <WithLayoutRoute component={Products} exact layout={DashboardLayout} path="/products" />
        <WithLayoutRoute component={Purchases} exact layout={DashboardLayout} path="/purchases" />
        <WithLayoutRoute component={Shops} exact layout={DashboardLayout} path="/shops" />
        <WithLayoutRoute component={Orders} exact layout={DashboardLayout} path="/orders" />
        <WithLayoutRoute component={LoadForm} exact layout={DashboardLayout} path="/load-form" />
        <WithLayoutRoute component={Expenses} exact layout={DashboardLayout} path="/expenses" />
        <WithLayoutRoute
          component={PushNotifications}
          exact
          layout={DashboardLayout}
          path="/pushNotifications"
        />
        <WithLayoutRoute
          component={ProductNotificationsSchedule}
          exact
          layout={DashboardLayout}
          path="/productNotificationsSchedule"
        />
        <WithLayoutRoute component={Riders} exact layout={DashboardLayout} path="/riders" />
        <Redirect to="/dashboard" />
      </Switch>
    </React.Suspense>
  );
}

export default Routes;
