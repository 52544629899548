export const showAddMsg = (snackbar, msg) => () => {
  snackbar('Add Successfully' + (msg ? ' - ' + msg : ''), { variant: 'success' });
};

export const showUpdateMsg = (snackbar, msg) => () => {
  snackbar('Update Successfully' + (msg ? ' - ' + msg : ''), { variant: 'success' });
};

export const showDeleteMsg = (snackbar, msg) => () => {
  snackbar('Deleted Successfully' + (msg ? ' - ' + msg : ''), { variant: 'success' });
};

export const showConflictError = (snackbar, msg) => () => {
  snackbar('Already Exists' + (msg ? ' - ' + msg : ''), { variant: 'warning' });
};

export const showRefuseDelError = (snackbar, msg) => () => {
  snackbar('Refuse to be deleted' + (msg ? ' - ' + msg : ''), { variant: 'warning' });
};

export const showSeverError = (snackbar, msg) => () => {
  snackbar('Error while processing request' + (msg ? ' - ' + msg : ''), { variant: 'error' });
};

export const showConnectionError = (snackbar, msg) => () => {
  snackbar('Unable to connect with server' + (msg ? ' - ' + msg : ''), { variant: 'error' });
};

export const showMsgSnackbar = (snackbar, msg) => () => {
  snackbar(msg);
};

export const showSuccessSnackbar = (snackbar, msg) => () => {
  snackbar(msg, { variant: 'success' });
};

export const showWarningSnackbar = (snackbar, msg) => () => {
  snackbar(msg, { variant: 'warning' });
};
export const showErrorSnackbar = (snackbar, msg) => () => {
  snackbar(msg, { variant: 'error' });
};
