import {
  showSeverError,
  showConnectionError,
  showConflictError,
  showRefuseDelError
} from './snackbar';

export const apiResponseErrors = (snackbar, msg) => ({
  onServerError: showSeverError(snackbar, msg),
  onError: showConnectionError(snackbar, msg),
  onConflict: showConflictError(snackbar, msg),
  onRefuse: showRefuseDelError(snackbar, msg)
});
