import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import ThemeProvider from './theme';
import history from './history';
import Routes from './routes/Routes';
import ThemeContext from 'theme/ThemeContext';
import { SnackbarProvider } from 'notistack';

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'reply');

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider>
        <SnackbarProvider maxSnack={5}>
          <Router history={history}>
            <Routes />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
