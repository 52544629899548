import _ from 'lodash';
import axios from 'axios';

//Axios Instance
var instance = axios.create({
  baseURL: 'https://api.karobarimarkaz.tech/v1', //For Production
  // baseURL: 'https://api.dev.karobarimarkaz.tech/v1', //For Development
  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
});

export const refreshApiInstance = () => {
  instance = axios.create({
    baseURL: 'https://api.karobarimarkaz.tech/v1', //For Production
    // baseURL: 'https://api.dev.karobarimarkaz.tech/v1', //For Development
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

window.addEventListener('storage', e => {
  refreshApiInstance();
});

const request = (method, url, data, onSuccess, onError, onFinish, config) => {
  instance({
    method,
    url,
    data,
    ...config
  })
    .then(res => {
      _.isFunction(onFinish) && onFinish();
      onSuccess(res);
    })
    .catch(err => {
      _.isFunction(onFinish) && onFinish();
      onError(err);
    });
};

export const getRequest = (endpoint, { onSuccess, onFinish, onServerError, onError }, config) => {
  request(
    'get', // Request Type
    endpoint, // Api Endpoint
    {}, // Data
    // Response
    res => {
      if (res.status === 200 && res.data.success) _.isFunction(onSuccess) && onSuccess(res);
      else _.isFunction(onServerError) && onServerError(res);
    },
    // Error
    err => {
      if (err.response && _.isFunction(onServerError)) onServerError(err);
      else _.isFunction(err) && onError(err);
    },
    onFinish, // On Finish in both case success & error
    config // Axios Configuration
  );
};

export const postRequest = (
  endpoint,
  data,
  { onSuccess, onFinish, onConflict, onServerError, onError },
  config
) => {
  request(
    'post', // Request Type
    endpoint, // Api Endpoint
    data, // Data
    // Response
    res => {
      if ((res.status === 200 || res.status === 201) && res.data.success)
        _.isFunction(onSuccess) && onSuccess(res);
      else _.isFunction(onServerError) && onServerError(res);
    },
    // Error
    err => {
      if (err.response && err.response.status === 409 && _.isFunction(onConflict)) onConflict(err);
      else if (err.response && err.response.status !== 409 && _.isFunction(onServerError))
        onServerError(err);
      else _.isFunction(err) && onError(err);
    },
    onFinish, // On Finish in both case success & error
    config // Axios Configuration
  );
};

export const putRequest = (
  endpoint,
  data,
  { onSuccess, onFinish, onConflict, onServerError, onError },
  config
) => {
  request(
    'put', // Request Type
    endpoint, // Api Endpoint
    data, // Data
    // Response
    res => {
      if (res.status === 200 && res.data.success) _.isFunction(onSuccess) && onSuccess(res);
      else _.isFunction(onServerError) && onServerError(res);
    },
    // Error
    err => {
      if (err.response && err.response.status === 409 && _.isFunction(onConflict)) onConflict(err);
      else if (err.response && err.response.status !== 409 && _.isFunction(onServerError))
        onServerError(err);
      else _.isFunction(err) && onError(err);
    },
    onFinish, // On Finish in both case success & error
    config // Axios Configuration
  );
};

export const deleteRequest = (
  endpoint,
  { onSuccess, onFinish, onRefuse, onServerError, onError },
  config
) => {
  request(
    'delete', // Request Type
    endpoint, // Api Endpoint
    {}, // Data
    // Response
    res => {
      if (res.status === 200 && res.data.success) _.isFunction(onSuccess) && onSuccess(res);
      else _.isFunction(onServerError) && onServerError(res);
    },
    // Error
    err => {
      if (err.response && err.response.status === 403 && _.isFunction(onRefuse)) onRefuse(err);
      else if (err.response && err.response.status !== 403 && _.isFunction(onServerError))
        onServerError(err);
      else _.isFunction(err) && onError(err);
    },
    onFinish, // On Finish in both case success & error
    config // Axios Configuration
  );
};
