import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
  Category,
  Business,
  Pages,
  ShoppingCart,
  Shop,
  Assignment,
  ExplicitRounded,
  Send,
  DirectionsBike,
  AssignmentTurnedIn
} from '@material-ui/icons';

const pages = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Categories',
    href: '/categories',
    icon: <Category />
  },
  {
    title: 'Companies',
    href: '/companies',
    icon: <Business />
  },
  {
    title: 'Products',
    href: '/products',
    icon: <Pages />
  },
  {
    title: 'Orders',
    href: '/orders',
    icon: <Assignment />
  },
  {
    title: 'Load Form',
    href: '/load-form',
    icon: <AssignmentTurnedIn />
  },
  {
    title: 'Purchases',
    href: '/purchases',
    icon: <ShoppingCart />
  },
  {
    title: 'Expenses',
    href: '/expenses',
    icon: <ExplicitRounded />
  },
  {
    title: 'Shops',
    href: '/shops',
    icon: <Shop />
  },
  {
    title: 'Riders',
    href: '/riders',
    icon: <DirectionsBike />
  },
  {
    title: 'Push Notifications',
    href: '/pushNotifications',
    icon: <Send />
  }
];

export default pages;
