import React, { useState, useEffect } from 'react';
import {
  Card,
  Box,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  TableFooter
} from '@material-ui/core';
import { postRequest } from 'helper/axios/apiInstance';
import { apiResponseErrors } from 'helper/snackbar/apiResponseErrors';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import withStyles from '@material-ui/core/styles/withStyles';

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 'bold',
    '&.text-center': {
      textAlign: 'center'
    },
    '&.text-right': {
      textAlign: 'right'
    }
  },
  body: {
    fontSize: 14
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 14,
    '&.text-right': {
      textAlign: 'right'
    }
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    '&.company > td': {
      fontWeight: 'bold',
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main
    },
    '&.category > td': {
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.secondary.main
    }
  }
}))(TableRow);

const LoadForm = ({ location }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loadForm, setLoadForm] = useState(null);

  const [query, setQuery] = useState({
    fromDate: new Date().toString(),
    toDate: new Date().toString()
  });

  const loadData = async (fromDate, toDate, orderIds) => {
    postRequest(
      'loadForm',
      {
        fromDate,
        toDate,
        orderIds
      },
      {
        onSuccess: res => {
          setLoadForm(res.data.data);
        },
        ...apiResponseErrors(enqueueSnackbar, 'Load Form')
      }
    );
  };

  useEffect(() => {
    if (location.state) loadData(undefined, undefined, location.state.orderIds);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            {/* <Card> */}
            <Grid container>
              <Grid item md={8} sm={12}>
                <Box p={3}>
                  <Typography variant="h5">Load Form</Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <Box p={2} pl={3}>
                        <DatePicker
                          style={{ marginTop: '4px' }}
                          format="MMM dd, yyyy"
                          value={query.fromDate}
                          onChange={newValue => {
                            setQuery({ ...query, fromDate: newValue });
                            loadData(newValue, query.toDate);
                          }}
                          renderInput={params => <TextField {...params} variant="outlined" />}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Box p={2} pl={3}>
                        <DatePicker
                          style={{ marginTop: '4px' }}
                          format="MMM dd, yyyy"
                          value={query.toDate}
                          onChange={newValue => {
                            setQuery({ ...query, toDate: newValue });
                            loadData(query.fromDate, newValue);
                          }}
                          renderInput={params => <TextField {...params} variant="outlined" />}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            {/* </Car/d> */}
          </Card>
        </Grid>

        {loadForm && (
          <Grid item xs={12}>
            <Paper square>
              <Table className={classes.table}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell className="text-center" colSpan={4}>
                      Shops Summary
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Shop Name</StyledTableCell>
                    <StyledTableCell>Owner</StyledTableCell>
                    <StyledTableCell>Mobile Number</StyledTableCell>
                    <StyledTableCell>Total (Rs)</StyledTableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {loadForm.shopsSummary.map(ele => (
                    <StyledTableRow key={ele.shop + ele.mobileNo}>
                      <StyledTableCell>{ele.shop}</StyledTableCell>
                      <StyledTableCell>{ele.owner}</StyledTableCell>
                      <StyledTableCell>{ele.mobileNo}</StyledTableCell>
                      <StyledTableCell>{numberWithCommas(ele.total)}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>

                <TableFooter>
                  <StyledTableRow>
                    <StyledTableCell className="text-right" colSpan={3}>
                      Total (Rs)
                    </StyledTableCell>
                    <StyledTableCell>
                      {numberWithCommas(
                        loadForm.shopsSummary.reduce((a, b) => a + b.total || 0, 0)
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Grid>
        )}

        {loadForm && (
          <Grid item xs={12}>
            <Paper square>
              <Table className={classes.table}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell className="text-center" colSpan={4}>
                      Products Summary
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Product</StyledTableCell>
                    <StyledTableCell>Quantity</StyledTableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {Object.keys(loadForm.productsSummary).map(company => {
                    return (
                      <>
                        <StyledTableRow key={company} className="company">
                          <StyledTableCell colSpan={4}>{company}</StyledTableCell>
                        </StyledTableRow>
                        {Object.keys(loadForm.productsSummary[company]).map(category => {
                          return (
                            <>
                              <StyledTableRow key={category} className="category">
                                <StyledTableCell colSpan={4}>{category}</StyledTableCell>
                              </StyledTableRow>

                              {Object.keys(loadForm.productsSummary[company][category]).map(
                                product => (
                                  <StyledTableRow key={product}>
                                    <StyledTableCell>{product}</StyledTableCell>
                                    <StyledTableCell>
                                      {numberWithCommas(
                                        loadForm.productsSummary[company][category][product]
                                      )}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LoadForm;
