import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

const WithLayoutRoute = props => {
  const { layout: Layout, component: Component, layoutProps, ...rest } = props;

  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  window.addEventListener('storage', e => {
    setIsAuthenticated(!!localStorage.getItem('token'));
  });

  if (isAuthenticated)
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout {...layoutProps} history={matchProps.history}>
            <React.Suspense
              fallback={
                <LinearProgress
                  style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '10000' }}
                />
              }
            >
              <Component {...matchProps} />
            </React.Suspense>
          </Layout>
        )}
      />
    );
  else return <Redirect to="/login" />;
};

WithLayoutRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  layoutProps: PropTypes.object
};

export default WithLayoutRoute;
